import { cn } from "../../utils/cn"

export const Button = ({className, onClick, children}) => {
    
    return(
        <button
            className={cn("transition-button", className)}
            onClick={onClick}
        >
            {children}
        </button>
    )
}