import React, { useState } from 'react';
import SectionHeader from '../../components/SectionHeader';
import { Button } from '../../components/ui/button';
import { Calendar, MapPin, CheckCircle, XCircle } from "lucide-react";
import { useNavigate } from 'react-router-dom';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import { Modal } from 'react-bootstrap';

// Event Data
const events = [
  {
    id: 1,
    name: "Veterans' Reel & Roam",
    date: "March 28, 2025",
    location: "Elk Springs Resort, WV",
    description: "Join us for a special weekend dedicated to our veterans, offering a chance to unwind, connect, and enjoy the great outdoors in the beautiful settings of West Virginia. This event is designed not just as a recreational outing, but as an opportunity to foster camaraderie and support among veterans, all while experiencing the therapeutic benefits of nature.",
    status: "Full",
  },
  {
    id: 2,
    name: "Spring Fishing Retreat",
    date: "May 17, 2024",
    location: "Shafers Fork-Elkins, WV",
    description: "Six participants attended, Branches of Service and Departments represented: Airforce, Army, Navy, Forestry, Local Flight Paramedic",
    status: "Over",
    trip_link: "/Shafers17May2024.pptx"
  },
  {
    id: 3, 
    name: "Golf Scramble", 
    date: "June 2, 2025", 
    location: "Berry Hills Country Club, Charleston, WV", 
    description: "To see more, click the view button below.", 
    status: "Open", 
    trip_link: "/GolfScramble2June2025.png"
  }
];

const EventViewer = ({show, onHide, event}) => {

  if(!event) return null; 

  const fileType = event.trip_link.split('.').pop();

  return (
    <Modal
      onHide={onHide}
      show={show}
      size="xl"
      fullscreen="xxl-down"
      style={{
        backgroundColor: "black"
      }}
    >
      <Modal.Header closeButton
        style={{
          backgroundColor: "black",
          color: "white"
        }}
      >
        {event.name}
      </Modal.Header>
      <Modal.Body
        style={{
          backgroundColor: "black",
          color: "white"
        }}
      >
        <DocViewer 
          documents={[{ uri: `https://${window.location.host}${event.trip_link}`, fileType: fileType}]} 
          pluginRenderers={DocViewerRenderers} 
          style={{
            height: "100%",
            backgroundColor: "black"
          }}
          config={{disableFileName: true, disableHeader: true}}  
        />
      </Modal.Body>
    </Modal>

  );
}

const Events = () => {
  const [filter, setFilter] = useState("upcoming");
  
  const [showEventViewer, setShowEventViewer] = useState(false); 
  const [activeEvent, setActiveEvent] = useState(null); 

  const filteredEvents = events.filter(event => {
    const eventDate = new Date(event.date);
    return filter === "upcoming" ? eventDate >= new Date() : eventDate < new Date();
  });

  const navigate = useNavigate();

  const handleContact = () => {
    navigate("/#contact");
  }

  const handleShowEvent = (event) => {
    setActiveEvent(event);
    setShowEventViewer(true);
  }


  return (
    <div style={{ marginTop: '2em', backgroundColor: '#000', color: '#fff' }}>
      <SectionHeader HeaderTitle={"Events"} />
      <div style={{ margin: "4em", textAlign: "center" }}>
        <div className="container mt-5">
          <h1 className="text-center mb-4">Upcoming & Past Events</h1>
          <div className="d-flex justify-content-center gap-2 mb-3">
            <Button className="event-filter-button" onClick={() => setFilter("upcoming")}>Upcoming Events</Button>
            <Button className="event-filter-button" onClick={() => setFilter("past")}>Past Events</Button>
          </div>
          <div className="row">
            {filteredEvents.map((event) => (
              <div key={event.id} className="col-md-4 mb-4 d-flex align-items-stretch">
                <div className="card bg-dark text-light shadow-sm w-100 d-flex flex-column">
                  <div className="card-body d-flex flex-column flex-grow-1">
                    <h5 className="card-title">{event.name}</h5>
                    <p className="card-text"><strong><Calendar className="text-white" /></strong> {event.date}</p>
                    <p className="card-text"><strong><MapPin className="text-white" /></strong> {event.location}</p>
                    <p className="card-text flex-grow-1">{event.description}</p>
                    {
                      event.status === "Over" ? <p className="badge bg-secondary">Event Over</p> : (<p className={`badge ${event.status === "Open" ? "bg-success" : event.status === "Full" ? "bg-danger" : "bg-warning"}`}>
                        {event.status === "Open" ? "Spots Available" : event.status === "Full" ? "Fully Booked" : "Waitlist Open"}
                      </p>)
                    }

                    {
                      event.status === "Over" ? 
                      <Button className="inverse" onClick={() => handleShowEvent(event)}>
                        View
                      </Button> : null
                    }
                    {/* <Button disabled={event.status === "Full"} className="inverse">{event.status === "Open" ? "Sign Up" : "More Info"}</Button> */}
                    {event.status === "Open" ? 
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                      <Button onClick={handleContact} className="inverse">Contact</Button>
                      <Button onClick={() => handleShowEvent(event)} className="inverse">View</Button>
                    </div> : null}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <EventViewer 
            event={activeEvent}
            show={showEventViewer}
            onHide={() => setShowEventViewer(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default Events;
