import React from 'react'; 
import SectionHeader from '../../components/SectionHeader';
import RecognitionCards from './RecognitionCards';
import RecognitionCarousel from './RecognitionCarousel';
import "./recognition.scss";
import Logo from '../../assets/logo.png'; 

const Recognition = () => {

    // Go to donation page when button is clicked
    const onClick = () => {
        window.open("https://www.paypal.com/donate/?hosted_button_id=UTVBMM7EDKFZG", "_blank");
    }

    return(
        <div
            className="recognition-content"
        >
            <SectionHeader HeaderTitle={"Recognition"}/>

            <div
                className="recognition-body"
            >
                <img
                    className="recognition-image"
                    src={Logo}
                />
                <p>
                We extend our heartfelt gratitude to our generous donors for their unwavering support and commitment to Healing Waters for Warriors. Your contributions have been instrumental in providing our veterans with the opportunity to experience the healing power of nature, fostering community, and rebuilding their sense of purpose. Thanks to your kindness, we continue to make a profound difference in the lives of those who have served our country. We are deeply appreciative of your dedication to our mission and look forward to achieving even greater impact together. Thank you for standing with us.
                </p>
            </div>
            <button className="recognition-donate-button" onClick={onClick}>Donate Now</button>

            <RecognitionCarousel />

        </div>
    )
}

export default Recognition; 